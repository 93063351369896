import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],

		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');

		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var req = {
			fields: this.resultFields,
			filter: {
				unitgroupid: 22228
			},
			sorting: '-stars',
			max: 9
		};

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;

			});

	},
	updated: function () {
		this.applySlider();

	},
	computed: {
		canLoop: function () {
			if (this.units && this.units.length > 3) {
				return true;
			}
			return false;
		}
	},
	methods: {
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},
		applySlider: function () {
			setTimeout(() => {
				$(".recommendations").owlCarousel({
					items: 3,
					loop: this.canLoop,
					margin: 30,
					smartSpeed: 3000,
					autoplay: true,
					nav: true,
					dots: false,
					responsive: {
						0: {
							items: 1,
							nav: false,
							dots: true,
							loop: true,
						},

						767: {
							items: 2,
							nav: false,
							dots: true,
							loop: true,
						},
						992: {
							items: 3,
							nav: true,
							dots: false
						},
						1000: {
							items: 3,
							nav: true,
							dots: false
						}

					}
				});
				$(".recommendations").addClass('owl-carousel');
			}, 50);

		}
	}

};