export default {
	data: {

		selectedImageIndex: undefined,
		showArrivalDesc: false,
		showMoreQuality: false

	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}
	}


};