import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';

export default {
	voVueComponent: 'image-gallery',
	data: function () {
		return {
			imagesPerPage: 6,
			displayedImages: 6
		};
	},
	props: {
		totalimages: Number
	},
	computed: {
		showMoreButton: function () {
			console.log('total images::' + this.totalimages);
			return (this.totalimages > 0 && this.displayedImages < this.totalimages);
			//return true;
		},
	},

	methods: {
		nextSet: function () {
			V.log("nextSet");
			this.displayedImages += this.imagesPerPage;
		}
	}
};